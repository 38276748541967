







































































































import { Component, Vue } from 'vue-property-decorator';
import workspaceModule from '@/store/modules/workspaceModule';
import { getComponent } from '@/utils/helpers';
import scheduleModule from '@/store/modules/scheduleModule';
import accountModule from '@/store/modules/accountModule';
import Multiselect from 'vue-multiselect';

@Component({
  components: {
	  Dropdown: () => getComponent('common/Dropdown'),
	Multiselect,
  },
})
export default class PersonnelScheduleAddScheduleModal extends Vue {

	scheduleSelected = ''
	// roleOptions = [{text: "Automation", value: "Automation", disabled: true},
	// {text: "Engineers", value: "Engineers"},
	// {text: "FieldOperator", value: "FieldOperator"},
	// {text: "Intervention", value: "Intervention"},
	// {text: "Maintenance", value: "Maintenance"},
	// {text: "Operators", value: "Operators"},
	// {text: "Optimizer", value: "Optimizer"},
	// {text: "SafetyCritical", value: "SafetyCritical"},
	// {text: "Wireline", value: "Wireline"},
	// {text: "Workover", value: "Workover"}]

	// get roleOptions() {
	// 	var roles = [{text: "Automation", value: "Automation", disabled: false},
	// 			{text: "Engineers", value: "Engineers", disabled: false},
	// 			{text: "FieldOperator", value: "FieldOperator", disabled: false},
	// 			{text: "Intervention", value: "Intervention", disabled: false},
	// 			{text: "Maintenance", value: "Maintenance", disabled: false},
	// 			{text: "Operators", value: "Operators", disabled: false},
	// 			{text: "Optimizer", value: "Optimizer", disabled: false},
	// 			{text: "SafetyCritical", value: "SafetyCritical", disabled: false},
	// 			{text: "Wireline", value: "Wireline", disabled: false},
	// 			{text: "Workover", value: "Workover", disabled: false}]
	// 	for (var x = 0; x < roles.length; x++) {
	// 		for (var y = 0; y < this.schedules.length; y++) {
	// 			if (this.schedules[y].Role == roles[x].value) {
	// 				roles[x].disabled = true
	// 			}
	// 		}
	// 	}
	// 	return roles
			
	// }

    get schedules(): any[] {
        return scheduleModule.personnelSchedules
    }


  async created() {


  }

  get isSavingAddNewSchedule() {
	  return scheduleModule.isSavingAddNewSchedule
  }

  addButtonClicked() {
	  if (this.scheduleSelected == '') return
	  // @ts-ignore
	  this.$emit('add-schedule-button-clicked', this.scheduleSelected)
  }





  closeButtonClicked() {
	  this.$emit("close-add-new-table-popup")
  }


}

